import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
// broadway models
import { BroadwayContactModel, BroadwayConversationModel, BroadwayMessageModel } from '@textel/broadway';
// event bus
import { EventBus, Channels } from '@/eventbus';

Vue.use(Vuex);

// api
const apiBaseUrl = process.env.VUE_APP_API_URL;
// cache
let fetchCache:Record<string, any> = {};

function localTimeFromUTC(date:Date):Date {
  const timeOffsetInMS = new Date().getTimezoneOffset() * 60000;
  return new Date(new Date(date).getTime() - timeOffsetInMS);
}

function buildQS(params:Record<string, string | undefined>) {
  return Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
}

async function fetchWithCache(ep:string, params:Record<string, string | undefined>, force = false, parseResponse = true):Promise<any> {
  const url = `${ep}?${buildQS(params)}`;
  if (!force && fetchCache != null && fetchCache[url] != null) {
    return fetchCache[url];
  }
  // new data
  console.log(`cache miss on ${url}, forced:${force}`);
  const response = await fetch(url);
  if (!parseResponse) return null;
  const payload = await response.json();
  fetchCache[url] = payload;
  return payload;
}

function formatNumberE164(source:string, addFormatChars = false):string {
  if (source.startsWith('+') || source.length < 10) return source;
  if (addFormatChars && source.length === 10) {
    return `+1 (${source.substring(0, 3)}) ${source.substring(3, 6)}-${source.substring(6)}`;
  }
  return `+1${source}`;
}

function getConvoContact(id?:string, conversationContactsJSON?:string):string | undefined {
  if (id == null || conversationContactsJSON == null) return undefined;
  const data:any = JSON.parse(conversationContactsJSON);
  const contact = data.find((x:any) => x.Phone_Number_v1 === Number.parseInt(id, 0));
  if (contact == null) return undefined;
  let result = contact.Last_Name;
  if (contact.First_Name != null) result = `${contact.First_Name} ${contact.Last_Name}`;
  return result;
}

function buildMessageModel(payloadJSON:string | undefined, payload:any, source:string | undefined, contactsJSON:string | undefined):BroadwayMessageModel {
  if (payloadJSON == null && payload == null) {
    throw Error('Must provide a payloadJSON or payload object');
  }
  if (source == null) {
    throw Error('Must provide a source id/number');
  }
  let data:any = payload;
  if (payloadJSON != null && payload == null) {
    [data] = JSON.parse(payloadJSON);
  }
  const messageSourceRaw = (data.Source != null) ? data.Source.toString() : source;
  const messageSource = formatNumberE164(messageSourceRaw);
  if (messageSource == null) console.log(payloadJSON);
  return {
    id: data.ID,
    body: (data.body == null || data.body === '') ? '>>empty<<' : data.body,
    status: (data.CarrierTimeStamp != null || data.utcTimeStamp != null) ? 2 : 1,
    sent: (data.utcTimeStamp != null) ? localTimeFromUTC(data.utcTimeStamp) : localTimeFromUTC(data.UTCTimeStamp),
    delivered: (data.CarrierTimeStamp != null) ? localTimeFromUTC(data.CarrierTimeStamp) : localTimeFromUTC(data.utcTimeStamp),
    received: (data.utcTimeStamp != null) ? localTimeFromUTC(data.utcTimeStamp) : localTimeFromUTC(data.UTCTimeStamp),
    read: undefined,
    author: new BroadwayContactModel(messageSourceRaw, messageSource, undefined, getConvoContact(messageSourceRaw, contactsJSON)),
    inbound: messageSourceRaw !== source.toString(),
    type: data.messageType,
  };
}

function buildConvoModel(c:any):BroadwayConversationModel {
  if (c.LastMessageJSON == null || c.LastMessageJSON.length === 0) throw Error('Missing lastMessageJSON field');
  const message:BroadwayMessageModel = buildMessageModel(c.LastMessageJSON, null, c.Source, c.ConversationContactsJSON);
  const agentName = (c.IC_agentFirstName != null
      && c.IC_agentFirstName !== ''
      && c.IC_agentLastName !== ''
      && c.IC_agentLastName !== null) ? `${c.IC_agentFirstName} ${c.IC_agentLastName}` : null;
    // let sourceAuthor = new BroadwayContactModel(c.source.toString(), c.source.toString(), undefined, authorName ?? undefined);
  const result:BroadwayConversationModel = {
    id: c.ConversationGUID,
    author: message.author,
    messages: [],
    status: 1,
    new_message_count: 0,
    last_message: message,
    wiid: c.WorkItemID,
    active_typing: false,
    line_phone_number: formatNumberE164(c.LinePhoneNumber.toString(), true),
    line_id: c.LineID,
    line_uid: c.LineGUID,
    end_date: new Date(c.EndDate),
  };
  if (agentName != null) {
    result.status = 2;
    result.owner = new BroadwayContactModel(c.Source.toString(), formatNumberE164(c.Source.toString()), 'https://via.placeholder.com/64', agentName);
  }
  return result;
}

interface RootState {
  chatActive: boolean;
  activeConvo: BroadwayConversationModel | undefined,
  snackMessage: string | undefined,
}

const store: StoreOptions<RootState> = {
  state: {
    chatActive: false,
    activeConvo: undefined,
    snackMessage: undefined,
  },
  mutations: {
    setChatActive(state, val) {
      state.chatActive = val;
    },
    setActiveConvo(state, val) {
      state.activeConvo = val;
      // update the bus
      if (val != null) EventBus.$emit(Channels.convoClicked, val);
    },
    showSnackBarMessage(state, val) {
      state.snackMessage = val;
      if (val != null && val.length > 0) EventBus.$emit(Channels.snackbarMessage, val);
    },
  },
  actions: {
    getConversations: async (context, args) => {
      const {
        aid, pageId, csearch, open, closed, lineUid, unowned, icaid, forced,
      }: {
        aid: string, pageId: number, csearch: string, open: boolean, closed: boolean, lineUid: string, unowned: boolean, icaid: string, forced: boolean,
      } = args;
      if (!aid || !pageId) throw Error('Must provide an aid and pageId');
      const result:Array<BroadwayConversationModel> = [];
      const payload = await fetchWithCache(
        `${apiBaseUrl}/v1/ConvosByAidPaged`, {
          aid, csearch, icaid, pg: pageId.toString(), ps: '20', open: open.toString(), closed: closed.toString(), lid: (lineUid == null) ? '' : lineUid, unowned: unowned?.toString(),
        },
        forced,
      );
      payload.map((d:any) => {
        if (d.LastMessageJSON == null || d.WorkItemID == null || d.WorkItemID === '' || d.WorkItemID === '00000000-0000-0000-0000-000000000000') return;
        result.push(buildConvoModel(d));
      });
      return result;
    },
    getConvoHistory: async (context) => {
      const wiid:string = context?.state?.activeConvo?.wiid ?? '';
      const source:string | undefined = context?.state?.activeConvo?.author?.id;
      const payload = await fetchWithCache(
        `${apiBaseUrl}/api/conversations/v1/get-history`,
        { ConversationId: wiid },
      );
      const result:Array<BroadwayMessageModel> = [];
      payload.map((d:any) => {
        result.push(buildMessageModel(undefined, d, source, undefined));
      });
      return result;
    },
    claimConversation: async (context, args) => {
      const {
        cid, icaid,
      }: {
        cid: string, icaid: string,
      } = args;
      if (!cid || !icaid) throw Error('Must provide an cid and agentId');
      await fetchWithCache(
        `${apiBaseUrl}/v1/AgentClaimConversation`, {
          ConversationId: cid, AgentId: icaid,
        },
        true,
        false,
      );
      // cache is dirty, clear it out
      // TODO might need to be more specific here as the site grows (segmented cache)
      fetchCache = {};
    },
  },
  getters: {
  },
};

export default new Vuex.Store<RootState>(store);

export { localTimeFromUTC, formatNumberE164 };
