












































import Vue, { PropType } from 'vue';
import { BroadwayConversationModel } from '@textel/broadway';
import { ConversationStatus } from '@textel/broadway/dist/src/shared/models/conversation';
import { EventBus, Channels } from '@/eventbus';

export default Vue.extend({
  name: 'Conversation',
  data():Record<string, unknown> {
    return {
      gstore: this.$store.state,
      isClaiming: false,
    };
  },
  computed: {
    isOwnedAndLocked() {
      // hide the claim button if this convo is already owned, closed, or we dont have enough data to claim
      return ((this.convo.status === ConversationStatus.Owned) || (this.convo.end_date?.getFullYear() !== 1) || (this.$route.query.icaid == null || this.$route.query.icaname == null));
    },
  },
  props: {
    convo: Object as PropType<BroadwayConversationModel>,
  },
  methods: {
    showChat(convo:BroadwayConversationModel) {
      // update global store
      this.$store.commit('setChatActive', true);
      this.$nextTick(() => {
        this.$store.commit('setActiveConvo', convo);
      });
    },
    async claim():Promise<void> {
      this.isClaiming = true;
      try {
        const result = await this.$store.dispatch('claimConversation', {
          cid: this.convo.wiid!,
          icaid: this.$route.query.icaid,
        });
        this.$emit('claim', this.convo.wiid);
      } catch (e) {
        console.error(e);
        EventBus.$emit(Channels.snackbarMessage, 'Error claiming conversation.');
      }
      this.isClaiming = false;
    },
  },
});
