












































import Vue from 'vue';
import { EventBus, Channels } from '@/eventbus';
import ConversationsView from '@/views/ConversationsView.vue';
import { LocalStorageKeys } from '@/keys';

export default Vue.extend({
  name: 'App',
  data() {
    return {
      drawer: true,
      showSnackbar: false,
      snackMessage: '',
      notificationsEnabled: false,
    };
  },
  components: {
    ConversationsView,
  },
  created() {
    EventBus.$on(Channels.snackbarMessage, this.handleSnackbarMessage);
  },
  mounted() {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        this.notificationsEnabled = true;
        localStorage.setItem(LocalStorageKeys.appNotificationsEnabled, 'true');
        return;
      }
    }
    this.notificationsEnabled = false;
    localStorage.setItem(LocalStorageKeys.appNotificationsEnabled, 'false');
  },
  beforeDestroy() {
    EventBus.$off(Channels.snackbarMessage, this.handleSnackbarMessage);
  },
  methods: {
    handleSnackbarMessage(message:string):void {
      this.snackMessage = message;
      this.showSnackbar = true;
    },
    toggleNotifications(enable:boolean):void {
      if ('Notification' in window) {
        if (enable) {
          Notification.requestPermission().then((result) => {
            if (result === 'granted') {
              this.notificationsEnabled = true;
              localStorage.setItem(LocalStorageKeys.appNotificationsEnabled, 'true');
            } else {
              this.notificationsEnabled = false;
              localStorage.setItem(LocalStorageKeys.appNotificationsEnabled, 'false');
            }
          });
          return;
        }
      }
      this.notificationsEnabled = false;
      localStorage.setItem(LocalStorageKeys.appNotificationsEnabled, 'false');
    },
  },
});
