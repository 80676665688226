






































































































































import Vue, { VueConstructor } from 'vue';
import { EventBus, Channels } from '@/eventbus';
import { hubConnection } from 'signalr-no-jquery';
import { localTimeFromUTC } from '@/store';
import { BroadwayContactModel, BroadwayConversationModel, BroadwayMessageModel } from '@textel/broadway';
import { LocalStorageKeys } from '@/keys';

const messagehubUrl = process.env.VUE_APP_HUB_URL;

export default (Vue as VueConstructor<
  Vue & {
  $refs: {
    chat: HTMLElement;
  };
}
>).extend({
  name: 'Chat',
  data() {
    return {
      messageText: '',
      isSidebar: true,
      isValidating: false,
      isValid: true,
      isLoading: true,
      isSending: false,
      emojisVisible: false,
      error: '',
      account: '',
      line: '',
      messagesByConvId: [] as Array<BroadwayMessageModel>,
      snackbar: false,
      filePickerVisible: false,
      messageHub: null as undefined | any,
      messageHubProxy: null as undefined | any,
      agentName: 'A',
      userName: 'C',
      conversationId: '' as string | undefined,
      conversationOwner: {} as BroadwayContactModel | undefined,
      conversationModel: {} as BroadwayConversationModel,
      attachments: [] as Array<any>,
      validationMessage: '',
      dropletSound: null as any,
    };
  },
  computed: {
    isOwnedAndLocked():boolean {
      // TODO: for now (until axcess) "close" and "release" are not available actions
      return true;
      // TODO: add check for owned by current user (owned and unlocked)
      // return this.conversationModel.status === ConversationStatus.Owned;
    },
  },
  created() {
    EventBus.$on(Channels.convoClicked, this.getHistory);
  },
  beforeDestroy() {
    if (this.messageHub != null) {
      this.messageHub.stop();
    }
    EventBus.$off(Channels.convoClicked, this.getHistory);
  },
  methods: {
    async getHistory(convo:BroadwayConversationModel):Promise<void> {
      try {
        this.snackbar = false;
        this.messagesByConvId = [];
        this.conversationId = convo.wiid;
        this.conversationOwner = convo.owner;
        this.conversationModel = convo;
        // start-up hub
        this.startMessageHub();
      } catch (e) {
        console.error(e);
        // TODO: i18n
        this.error = 'Error Loading Message History';
        this.snackbar = true;
        this.isLoading = false;
      }
    },
    startMessageHub():void {
      this.isLoading = true;
      // do we have a hub running? if so, (re)connect to the new convo
      if (this.messageHub != null) {
        console.log('hub exists, stop the current one');
        this.messageHub.stop();
      }

      console.log('creating a new message hub');
      this.messageHub = hubConnection(messagehubUrl);
      this.messageHubProxy = this.messageHub.createHubProxy('msghub1');

      this.messageHubProxy.on('addedToGroup', (group:string) => this.addedToGroup(group));
      this.messageHubProxy.on('showExisting', (messages:Array<any>) => this.showExistingMessages(messages));
      this.messageHubProxy.on('broadcastMessage', (messages:Array<any>) => this.broadcastMessage(messages));
      this.messageHubProxy.on('setAgentName', (name:string) => this.setAgentName(name));
      // this.messageHubProxy.on('setMessageStatus', (messageId, status) => this.setMessageStatus(messageId, status));

      this.messageHub.start().done(() => {
        console.log('messagehub connected. Calling afterstart.');
        this.messageHubAfterStart();
      }).fail((e:any) => {
        this.isValidating = true;
        this.isLoading = false;
        this.validationMessage = 'Error Loading Messages';
        console.error(e);
      });
    },
    messageHubAfterStart():void {
      if (this.conversationId == null) return;
      console.log(`joining group ${this.conversationId.toString()}`);
      this.messageHubProxy.invoke('joinGroup', this.conversationId.toString())
        .done(() => {
          this.messageHubProxy.invoke('getExistingMessages', this.conversationId!.toString()).fail((e:any) => { console.error(e.stack); console.log(e.name); });
        }).fail((e:any) => console.error(e));
    },
    addedToGroup(group:string):void {
      console.log(group);
    },
    showExistingMessages(messages:Array<any>):void {
      if (this.messagesByConvId != null && this.messagesByConvId.length > 0) return;
      let i = 0;
      console.log('existing messages from hub');
      for (i; i < messages.length; i++) {
        this.messagesByConvId.push(this.buildMessageModel(messages[i]));
      }
      // this.messages = messages;
      this.isLoading = false;
      this.$nextTick(() => {
        if (this.$refs.chat) this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
      });
    },
    broadcastMessage(messages:Array<any>):void {
      let i = 0;
      this.isSending = false;
      for (i; i < messages.length; i++) {
        this.messagesByConvId.push(this.buildMessageModel(messages[i]));
      }
      this.$nextTick(() => {
        if (this.$refs.chat) this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        this.messageNotify(this.messagesByConvId[this.messagesByConvId.length - 1]);
      });
      if (this.dropletSound != null) {
        this.dropletSound.play();
      }
    },
    buildMessageModel(d:any):BroadwayMessageModel {
      const messageSource = (d.msgFrom.startsWith('+') || d.msgFrom.length < 7) ? d.msgFrom : `+1${d.msgFrom}`;
      let author = new BroadwayContactModel(d.msgFrom, messageSource, undefined);
      if (d.msgFrom === this.conversationModel.author?.id) {
        author = this.conversationModel.author;
      }
      return {
        id: d.msgID,
        body: (d.msgBody == null || d.msgBody === '') ? '' : d.msgBody,
        status: (d.msgTimeStamp != null) ? 2 : 1,
        sent: localTimeFromUTC(d.msgTimeStamp),
        delivered: localTimeFromUTC(d.msgTimeStamp),
        received: localTimeFromUTC(d.msgTimeStamp),
        read: undefined,
        author,
        inbound: d.msgType !== 'TO',
        type: d.msgType,
        attachmentUrl: d.msgAttachment,
      };
    },
    setAgentName(name:string):void {
      console.log(name);
      this.agentName = name;
    },
    sendMessage():void {
      if (this.messageText) {
        this.isSending = true;
        this.messageHub.invoke('sendMessageCTS', this.agentName, this.messageText, this.conversationId);

        if (this.emojisVisible) {
          this.toggleEmojisVisible();
        }
      }

      if (this.attachments && this.attachments.length > 0) {
        const formData = new FormData();

        formData.append('Wiid', this.conversationId!);
        for (let i = 0; i < this.attachments.length; ++i) {
          formData.append('Files', this.attachments[i]);
        }

        fetch('/api/conversations/v1/upload-file', {
          method: 'post',
          body: formData,
        });

        if (this.filePickerVisible) {
          this.toggleFilePickerVisible();
        }
      }

      this.messageText = '';
      this.attachments = [];
    },
    toggleEmojisVisible():void {
      this.emojisVisible = !this.emojisVisible;
      this.filePickerVisible = false;
      this.attachments = [];
    },
    selectEmoji(emoji:any):void {
      this.messageText += emoji.data;
    },
    toggleFilePickerVisible():void {
      this.emojisVisible = false;
      this.filePickerVisible = !this.filePickerVisible;
    },
    fileAdded(files:any):void {
      for (let i = 0; i < files.length; ++i) {
        this.attachments.push(files[i]);
      }
    },
    fileRemoved(index:number):void {
      this.attachments.splice(index, 1);
    },
    messageNotify(message:BroadwayMessageModel | undefined) {
      let notification = null;
      if (message === undefined) return;
      if (localStorage.getItem(LocalStorageKeys.appNotificationsEnabled) === 'true') {
        const source = (message.author.hasName) ? message.author.name : message.author.e164_phone_number;
        const notificationBody = (message.attachmentUrl != null) ? 'image' : message.body;
        notification = new Notification(`SMS Inbox - New message from ${source}`, { body: notificationBody });
      }
    },
  },
});
