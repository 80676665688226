























































































import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ConversationFilters',
  data() {
    return {
      localSelectedLineId: '',
      localShowOpen: true,
      localShowClosed: false,
      localSearchVal: '',
      localSelectedTab: 0,
    };
  },
  props: {
    filterShowOpen: {
      type: Boolean,
      required: true,
    },
    filterShowClosed: {
      type: Boolean,
      required: true,
    },
    filterSearchVal: {
      type: String,
      default: '',
    },
    filterSelectedLineId: {
      type: String,
      required: true,
    },
    filterSelectedTabId: {
      type: Number,
      required: true,
    },
    lines: {
      type: Array as PropType<Array<string>>,
    },
    showLineFilter: {
      type: Boolean,
      default: true,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    filterSelectedTabId: {
      handler(newValue:number) {
        this.localSelectedTab = newValue;
      },
    },
    filterShowOpen: {
      handler(newValue:boolean) {
        this.localShowOpen = newValue;
      },
    },
    filterShowClosed: {
      handler(newValue:boolean) {
        this.localShowClosed = newValue;
      },
    },
    filterSearchValue: {
      handler(newValue:string) {
        if (newValue != null) {
          this.localSearchVal = newValue;
        }
      },
    },
    filterSelectedLineId: {
      handler(newValue:string) {
        if (newValue != null) {
          this.localSelectedLineId = this.filterSelectedLineId;
        }
      },
    },
  },
  methods: {
    tabChanged(tabIndex:number) {
      this.$emit('update:filterSelectedTabId', tabIndex);
    },
    lineSelected(line:string) {
      this.$emit('update:filterSelectedLineId', line);
    },
    searchChanged(val:string) {
      if (val == null || typeof (val) === 'object') {
        this.localSearchVal = '';
      } else {
        this.localSearchVal = val.trim()
          .replaceAll('-', '')
          .replaceAll(' ', '')
          .replaceAll('(', '')
          .replaceAll(')', '');
      }
      this.$emit('update:filterSearchValue', this.localSearchVal);
    },
    openChanged(open:boolean) {
      this.$emit('update:filterShowOpen', open);
    },
    closeChanged(close:boolean) {
      this.$emit('update:filterShowClosed', close);
    },
  },
});
