

































































































import Vue from 'vue';
import ConversationFilters from '@/components/ConversationFilters.vue';
import Conversation from '@/components/Conversation.vue';
import Chat from '@/components/Chat.vue';
import globalStore from '@/store';
import {
  BroadwayConversationModel,
  BroadwayContactModel,
  BroadwayMessageModel,
  BroadwayConverstationStatus,
} from '@textel/broadway';
import { EventBus, Channels } from '@/eventbus';
import { LocalStorageKeys } from '@/keys';

const ALL_LINES_VAL = 'All Lines';

export default Vue.extend({
  components: { ConversationFilters, Conversation, Chat },
  name: 'ConversationsView',
  data() {
    return {
      filterShowOpen: true,
      filterShowClosed: false,
      selectedLineVal: ALL_LINES_VAL,
      searchVal: '',
      selectedFilterTab: 0,
      lines: [ALL_LINES_VAL],
      uniqueLineIndex: {} as Map<string, string>,
      gstore: globalStore.state,
      isLoading: true,
      isFiltering: false,
      convos: [] as Array<BroadwayConversationModel>,
      convoIDsRead: {} as Map<string, boolean>,
      page: 1,
      timer: 0,
      showRefreshSnackbar: false,
      filterShowTabs: false,
    };
  },
  watch: {
    filterShowOpen: {
      async handler() {
        await this.doFilter();
      },
    },
    filterShowClosed: {
      async handler() {
        await this.doFilter();
      },
    },
    searchVal: {
      async handler() {
        await this.doFilter();
      },
    },
    selectedLineVal: {
      async handler() {
        await this.doFilter();
      },
    },
    selectedFilterTab: {
      async handler() {
        await this.doFilter();
      },
    },
  },
  computed: {
    rootDivClass():any {
      return {
        'd-flex': !this.$vuetify.breakpoint.mobile,
      };
    },
    outerConvoDivClass():any {
      return {
        'd-flex flex-grow-1 rounded-t-lg rounded-bl-lg': !this.$vuetify.breakpoint.mobile,
      };
    },
    outerChatDivClass():any {
      return {
        'white outerchatdiv-desktop': !this.$vuetify.breakpoint.mobile,
        'outerchatdiv-mobile py-2 white elevation-1 rounded info lighten-5': this.$vuetify.breakpoint.mobile,
      };
    },
    innerChatDivClass():any {
      return {
        'rounded scroll-panel-chat d-flex': !this.$vuetify.breakpoint.mobile,
        'd-flex innerchatdiv-mobile': this.$vuetify.breakpoint.mobile,
      };
    },
  },
  created() {
    this.uniqueLineIndex = new Map<string, string>();
    this.convoIDsRead = new Map<string, boolean>();
  },
  async mounted() {
    try {
      this.convos = await this.getConvos(true);
      // start polling here
      this.timer = setInterval(this.newConvoCheck, 30000);
      // fill line drop-down with unique line numbers
      this.fillLines();
      this.isLoading = false;
      // if we are on a desktop, select the first convo on the next tick and set the chat active
      if (!this.$vuetify.breakpoint.mobile) {
        this.$store.commit('setChatActive', true);
        this.$nextTick(() => {
          this.$store.commit('setActiveConvo', this.convos[0]);
        });
      }
    } catch (e) {
      console.error(e);
      EventBus.$emit(Channels.snackbarMessage, 'Error retrieving conversations.');
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async getMoreConvos(page:number):Promise<void> {
      if (page === 1) return;
      this.page = page;
      const newPageData = await this.getConvos(false);
      this.convos = this.convos.concat(newPageData);
      this.isLoading = false;
    },
    deselectConvo():void {
      this.$store.commit('setActiveConvo', null);
      this.$store.commit('setChatActive', false);
    },
    fillLines():void {
      for (let i = 0; i < this.convos.length; i++) {
        if (!this.uniqueLineIndex.has(this.convos[i].line_phone_number)) {
          // ensure the line data is solid
          if (this.convos[i].line_uid === undefined) return;
          this.lines.push(this.convos[i].line_phone_number);
          this.uniqueLineIndex.set(this.convos[i].line_phone_number, this.convos[i].line_uid!);
        }
      }
    },
    getRouteVal(key:string):string | null {
      if (this.$route.query[key] === null || this.$route.query[key] === 'undefined') {
        return null;
      }
      return this.$route.query[key] as string;
    },
    async getConvos(forced:boolean, markAllRead = true):Promise<Array<BroadwayConversationModel>> {
      const routeLid = this.getRouteVal('lid');
      const routeAid = this.getRouteVal('aid');
      const routeIcaid = this.getRouteVal('icaid');
      // force lineUid if in the qs
      let lineUid = null;
      if (routeLid != null) {
        lineUid = routeLid;
      } else {
        lineUid = (this.selectedLineVal === ALL_LINES_VAL) ? null : this.selectedLineVal;
      }
      const convos = await this.$store.dispatch('getConversations', {
        aid: routeAid,
        pageId: this.page,
        csearch: this.searchVal,
        open: this.filterShowOpen,
        closed: this.filterShowClosed,
        lineUid,
        unowned: (this.selectedFilterTab === 1) ? true : null,
        icaid: ((this.selectedFilterTab === 2) && (routeIcaid != null)) ? routeIcaid : '',
        forced,
      });
      if (markAllRead) {
        for (let i = 0; i < convos.length; i++) {
          this.convoIDsRead.set(convos[i].id, true);
        }
      }
      return convos;
    },
    async getNewConvos():Promise<Array<BroadwayConversationModel>> {
      const routeLid = this.getRouteVal('lid');
      const routeAid = this.getRouteVal('aid');
      // force lineUid if in the qs
      return this.$store.dispatch('getConversations', {
        aid: routeAid,
        pageId: 1,
        csearch: '',
        open: true,
        closed: false,
        lineUid: (routeLid == null) ? null : routeLid,
        unowned: null,
        icaid: '',
        forced: true,
      });
    },
    async newConvoCheck():Promise<void> {
      try {
        const convos = await this.getNewConvos();
        if (convos == null || convos.length < 1) return;
        // compare the initial convos with the new set
        // newest convo only - only want one notification per poll
        let newestConvo = null;
        for (let i = 0; i < convos.length; i++) {
          if (!this.convoIDsRead.has(convos[i].id)) {
            if (newestConvo == null) newestConvo = convos[i];
            this.convoIDsRead.set(convos[i].id, true);
          }
        }
        if (newestConvo == null) return;
        this.conversationNotify(newestConvo?.last_message);
      } catch (e) {
        console.error(e);
      }
    },
    async refreshList():Promise<void> {
      // reset filter values
      this.showRefreshSnackbar = false;
      this.selectedFilterTab = 0;
      this.searchVal = '';
      this.filterShowOpen = true;
      this.filterShowClosed = false;
      this.selectedLineVal = ALL_LINES_VAL;
      this.fillLines();
      await this.doFilter(true);
    },
    async doFilter(force = false):Promise<void> {
      this.page = 1;
      this.isFiltering = true;
      try {
        this.convos = await this.getConvos(force);
      } catch (e) {
        console.error(e);
        EventBus.$emit(Channels.snackbarMessage, 'Error filtering conversations.');
      }
      this.isFiltering = false;
    },
    onConversationClaim(cid:string):void {
      const routeIcaid = this.getRouteVal('icaid');
      const routeIcaname = this.getRouteVal('icaname');
      if (routeIcaid === null || routeIcaname === null) {
        throw Error('Invalid route parameters.');
      }
      // update the owner in memory
      const matches = this.convos.filter((c:BroadwayConversationModel) => c.wiid === cid);
      if (matches != null && matches.length > 0) {
        matches[0].owner = new BroadwayContactModel(routeIcaid, routeIcaid, 'https://via.placeholder.com/64', routeIcaname);
        matches[0].status = BroadwayConverstationStatus.Owned;
      } else {
        console.error(`Error updating conversation owner in-memory. id=${cid}`);
      }
    },
    conversationNotify(message:BroadwayMessageModel | undefined) {
      let notification = null;
      if (message === undefined) return;
      if (localStorage.getItem(LocalStorageKeys.appNotificationsEnabled) === 'true') {
        const source = (message.author.hasName) ? message.author.name : message.author.e164_phone_number;
        const notificationBody = (message.attachmentUrl != null) ? 'image' : message.body;
        notification = new Notification(`SMS Inbox - New conversation from ${source}`, { body: notificationBody });
      }
      this.showRefreshSnackbar = true;
    },
  },
});
