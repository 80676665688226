import Vue from 'vue';
import vuetify from '@textel/broadway/src/plugins/vuetify';
import '@textel/broadway/src/shared/styles/global.scss';
import '@textel/broadway';
import VScrollLock from 'v-scroll-lock';
import router from './router';
import store from './store';
import App from './App.vue';

Vue.config.productionTip = false;
Vue.use(VScrollLock);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
